export const videoPriceSummaryData = [
  {
    plan: "Starter",
    data: [
      {
        label: "Video Storage (mins total)",
        value: 1500,
      },
      {
        label: "Video Streaming Included",
        value: "2TB",
      },
      {
        label: "Streaming overage ($/GB)",
        value: "$0.15",
      },
    ],
  },
  {
    plan: "Growth",
    data: [
      {
        label: "Video Storage (mins total)",
        value: 6000,
      },
      {
        label: "Video Streaming Included",
        value: "2TB",
      },
      {
        label: "Streaming overage ($/GB)",
        value: "$0.10",
      },
    ],
  },
  {
    plan: "Business",
    data: [
      {
        label: "Video Storage (mins total)",
        value: 15000,
      },
      {
        label: "Video Streaming Included",
        value: "8TB",
      },
      {
        label: "Streaming overage ($/GB)",
        value: "$0.04",
      },
    ],
  },
];

export const imagePriceSummaryData = [
  {
    plan: "Free",
    data: [
      {
        label: "Bandwidth Included",
        value: "30GB",
      },
      {
        label: "Bandwidth Overage",
        value: "$0.5/GB",
      },
    ],
  },
  {
    plan: "Growth",
    data: [
      {
        label: "Bandwidth Included",
        value: "300GB",
      },
      {
        label: "Bandwidth Overage",
        value: "$0.15/GB",
      },
    ],
  },
  {
    plan: "Business",
    data: [
      {
        label: "Bandwidth Included",
        value: "2500GB",
      },
      {
        label: "Bandwidth Overage",
        value: "$0.08/GB",
      },
    ],
  },
];
