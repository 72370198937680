export const REPORT_FREQUENCY = [
    {
        "value": "daily",
        "label": "Daily"
    },
    {
        "value": "weekly",
        "label": "Weekly"
    },
    {
        "value": "monthly",
        "label": "Monthly"
    }
]