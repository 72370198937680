import { routePaths } from "global/routePaths";

export const IMAGE_MENU = [
  {
    name: "Source Details",
    icon: "bi bi-card-image",
    activeLabel: "Edit Source",
    id: "source_details",
    item_route: routePaths.image.sources.editsource,
  },
  {
    name: "Parameters",
    icon: "bi bi-sliders2-vertical",
    activeLabel: "Parameters",
    id: "parameters",
    item_route: routePaths.image.sources.editsource,
  },
  {
    name: "Setup Instruction",
    icon: "bi bi-journal-code",
    activeLabel: "Setup Instruction",
    id: "instruction",
    item_route: routePaths.image.sources.setupinstruction,
  },
  {
    name: "Custom Domain",
    icon: "bi bi-postcard",
    activeLabel: "Custom Domain",
    id: "domain",
    item_route: routePaths.image.sources.customdomain,
  },
  {
    name: "Security",
    icon: "bi bi-shield-check",
    activeLabel: "Security",
    id: "security",
    item_route: routePaths.image.sources.security,
  },
  {
    name: "Cache Management",
    icon: "bi bi-clock-history",
    activeLabel: "Cache Management",
    id: "cache",
    item_route: routePaths.image.sources.cachepolicy,
  },
  {
    name: "Verify Source",
    icon: "bi bi-patch-check",
    activeLabel: "Verify Source",
    id: "verify",
    item_route: routePaths.image.sources.verify,
  },
  {
    name: "Action",
    icon: "bi bi-trash3",
    activeLabel: "Source Delete",
    id: "delete",
    item_route: routePaths.image.sources.deletesource,
  },
];
