import React, { Fragment } from "react";
import { Success } from "assets/images";
import { Error } from "assets/images";
import { Pending } from "assets/images";
import { NavLink } from "react-router-dom";
import { routePaths } from "global/routePaths";
import Cookies from "js-cookie";

export default function SubscriptionProcess({ apiResponse, activeTab }) {
  function redirect() {
    if (activeTab === "image") {
      return (
        <NavLink
          to={routePaths.image.sources.allsources}
          className="btn btn-primary"
        >
          Optimize Images
        </NavLink>
      );
    } else {
      return (
        <NavLink to={routePaths.video.cms.manage} className="btn btn-primary">
          Upload Videos
        </NavLink>
      );
    }
  }

  if (apiResponse.invoice_status === "paid") {
    return (
      <Fragment>
        <div className="card-body text-center">
          <div className="mb-4">
            <img
              src={Success}
              className="empty-data mx-auto d-block"
              alt="empty"
            />
          </div>
          <div className="mb-3">
            <h3>Payment Successful</h3>
            <p>Your payment was successful.</p>
          </div>
          <div className="mt-10">{redirect()}</div>
        </div>
      </Fragment>
    );
  } else if (apiResponse.invoice_status === "failed") {
    Cookies.set("payment_failed", apiResponse?.error_message);
    return (
      <div className="card-body text-center">
        <div className="mb-4">
          <img src={Error} className="empty-data mx-auto d-block" alt="empty" />
        </div>
        <div className="mb-3">
          <h3>Payment Failed</h3>
          <p>
            Your payment failed. Please try again with a different payment
            method.
          </p>
        </div>

        <div className="mt-10">
          <a
            className="btn btn-primary"
            href={apiResponse.hosted_invoice_url}
            target="_blank"
            rel="noreferrer"
          >
            Retry Payment
          </a>
        </div>
      </div>
    );
  } else if (apiResponse.invoice_status === "requires_action") {
    return (
      <div className="card-body text-center">
        <div className="mb-4">
          <img
            src={Pending}
            className="empty-data mx-auto d-block"
            alt="empty"
          />
        </div>
        <div className="mb-3">
          <h3>Payment Pending</h3>
          <p>
            Your payment requires an additional step. Click the button to
            authorize the payment.
          </p>
        </div>

        <div className="mt-10">
          <a
            className="btn btn-primary"
            href={apiResponse.hosted_invoice_url}
            target="_blank"
            rel="noreferrer"
          >
            Authorize Now
          </a>
        </div>
      </div>
    );
  }
}
