import React, { useContext } from "react";
import featureAccessMap from "global/rbac.json";
import DataProvider from "context/DataContext";

function AccessComponent({ path, userData, children, fallback }) {
  const hasAccessRoutes = () => {
    if (typeof path === "undefined") {
      return false;
    }
    //middle ware logic to authenticate request routes
    //split the routes ..

    var route = path;

    if (path.indexOf("https") > -1) {
      /* eslint-disable no-useless-escape */
      route = path.replace(/^.*\/\/[^\/]+/, "");
    }

    var maxLevel = 2;

    //this function validate whether org has particular feature access or not based on user's current roles.
    let levels = route.split("/");
    if (levels.length < 1) {
      return false;
    }
    let validateRoute = roleHasAccess(
      userData.roles,
      levels.slice(1, maxLevel + 1)
    );
    if (validateRoute) return true;
    else return false;
  };

  function roleHasAccess(roles, accessLevels) {
    if (typeof accessLevels === "undefined") {
      return false;
    }
    accessLevels = [].concat(accessLevels);
    // Goes through all access levels, and if any one of the access levels
    // doesn't exist in the roles, return false
    return !accessLevels.some((level) => {
      var accesses = featureAccessMap[level] || [];
      var intersection = roles.filter((value) => accesses.includes(value));
      if (intersection.length === 0) return true;
      return false;
    });
  }

  if (hasAccessRoutes()) {
    return children;
  } else if (fallback) {
    return fallback;
  } else {
    return <></>;
  }
}

const RbacWrapper = ({ path, children, fallback }) => {
  const { userData } = useContext(DataProvider);

  return (
    <>
      {userData.id ? (
        <AccessComponent
          path={path}
          userData={userData}
          children={children}
          fallback={fallback}
        />
      ) : (
        <></>
      )}
    </>
  );
};

export default RbacWrapper;
