import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { routePaths } from "global/routePaths";

const NoInternetConnection = (props) => {

  const siteUrl = routePaths.home;
  const host = window.location.host;
  const url = siteUrl.replace("www.gumlet.com", host);
  // state variable holds the state of the internet connection
  const [isOnline, setOnline] = useState(true);

  // On initization set the isOnline state.
  useEffect(() => {
    setOnline(navigator.onLine);
  }, []);

  // event listeners to update the state
  window.addEventListener("online", () => {
    setOnline(true);
  });

  window.addEventListener("offline", () => {
    setOnline(false);
  });

  // if user is online, return the child component else return a custom component
  if (isOnline) {
    return props.children;
  } else {
    return (
      <section
        className="pt-md-13 pb-11 pb-md-10"
        style={{ paddingTop: "150px" }}
      >
        <div className="container-lg">
          <div className="row align-items-center justify-content-center">
           
            <div className="col-lg-10 col-md-10 col-12 order-md-1 text-center">
              {/* <h6 className="text-uppercase text-primary mb-6">
                404 Not Found
              </h6> */}
              <h3 className="display-4 mb-5">
              No Internet Connection. Please check your connection.
              </h3>
              <NavLink to={url} className="btn btn-danger lift">
                Try Again
              </NavLink>
            </div>
          </div>
        </div>
      </section>
    );
  }
};

export default NoInternetConnection;
