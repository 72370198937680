import React, { Fragment } from "react";

export default function Switch({
  summary,
  stripe,
  toggle,
  handleYearlyPriceToggle,
}) {
  return (
    <Fragment>
      {!summary && !stripe && (
        <div className="d-flex justify-content-center align-items-center fs-6 mb-2 mb-lg-0">
          <span
            className={`fs-6 ${
              toggle ? "text-secondary" : "text-dark fw-bold"
            }`}
          >
            Monthly
          </span>
          <label
            className="form-check form-switch form-switch-between ms-2"
            htmlFor="customSwitch"
          >
            <input
              className="form-check-input"
              id="customSwitch"
              type="checkbox"
              checked={toggle}
              onChange={(e) => handleYearlyPriceToggle(e)}
              style={{ fontSize: "0.7rem", marginTop: "1px" }}
            />
            <span className="toggle-switch-label">
              <span className="toggle-switch-indicator" />
            </span>
          </label>
          <span
            className={`fs-6 ${
              !toggle ? "text-secondary" : "text-primary fw-bold"
            }`}
          >
            Yearly (<b>save upto 50%</b>)
          </span>
        </div>
      )}
    </Fragment>
  );
}
