import { routePaths } from "global/routePaths";

export const IMAGE_REPORT_MENU = [
  {
    name: "Report Details",
    icon: "bi bi-envelope-check",
    activeLabel: "Image Sources",
    id: "report_details",
    item_route: routePaths.image.reports.editreport,
  },
  {
    name: "Action",
    icon: "bi bi-trash3",
    activeLabel: "Sources Delete",
    id: "delete",
    item_route: routePaths.image.reports.deletereport,
  },
];
