import { routePaths } from "global/routePaths";

export const VIDEO_MENU = [
  {
    name: "Collection Details",
    icon: "bi bi-card-text",
    activeLabel: "Video Collections",
    id: "collection_details",
    item_route: routePaths.video.sources.editsource,
  },
  {
    name: "Player Settings",
    icon: "bi bi-sliders",
    activeLabel: "Embed Setting",
    id: "embed_setting",
    item_route: routePaths.video.sources.embededsettings,
  },
  {
    name: "Channel Settings",
    icon: "bi bi-sliders",
    activeLabel: "Embed Setting",
    id: "channel_setting",
    item_route: routePaths.video.sources.channelsettings,
  },
  {
    name: "Video Protection",
    icon: "bi bi-shield-check",
    activeLabel: "Video Protection",
    id: "video_protection",
    item_route: routePaths.video.sources.videoprotection,
  },
  {
    name: "Action",
    icon: "bi bi-trash3",
    activeLabel: "Collection Delete",
    id: "delete",
    item_route: routePaths.video.sources.deletesource,
  },
];
