import React, {
  useEffect,
  useState,
  useContext,
  forwardRef,
  useImperativeHandle,
} from "react";
import { loadStripe } from "@stripe/stripe-js";
import {
  Elements,
  PaymentElement,
  AddressElement,
  useStripe,
  useElements,
  CardElement,
} from "@stripe/react-stripe-js";
import { config } from "global/config";
import DataProvider from "context/DataContext";
import http from "utils/http";
import toast from "react-hot-toast";
import { endpoints } from "global/endpoints";
import { stripeCardStyle } from "utils/constants";
import AnalyticsPlaceholder from "../Placeholder/AnalyticsPlaceholder";

function CardForm({
  setSummary,
  setStripe,
  setStripeLoading,
  userData,
  stripeElementRef,
  confirmedPlan,
  coupon,
}) {
  const stripe = useStripe();
  const elements = useElements();

  useImperativeHandle(stripeElementRef, () => ({
    handleSubmitFromCardForm,
  }));

  const handleSubmitFromCardForm = async () => {
    if (elements == null) {
      return;
    }
    setStripeLoading(true);
    const { setupIntent, error } = await stripe.confirmSetup({
      elements,
      redirect: "if_required",
    });
    if (setupIntent) {
      getCardData();
    } else if (error) {
      setStripeLoading(false);
      toast.error(error.message);
    }
  };

  const getCardData = async () => {
    try {
      const data = await http().get(endpoints.organization.billing.card.base);
      if (data) {
        onDefaultCard(data.cards[0].id);
      }
    } catch (error) {
      toast.error(error.error.message);
    }
  };

  const onDefaultCard = async (id) => {
    let payload = {
      payment_method_id: id,
      auto: true,
    };

    try {
      await http().post(endpoints.organization.billing.card.default, payload);
      setSummary(true);
      setStripe(false);
      setStripeLoading(false);
    } catch (error) {
      setStripeLoading(false);
      toast.error(error.error.message);
    }
  };

  return (
    <div>
      <h3 className="flex mb-4">
        Subscribe to{" "}
        {confirmedPlan?.type?.charAt(0)?.toUpperCase() +
          confirmedPlan?.type?.slice(1)}{" "}
        {confirmedPlan?.plans} -{" "}
        {confirmedPlan?.is_yearly ? "Annually" : "Monthly"}
      </h3>
      <div className="bg-white rounded">
        <AddressElement options={{ mode: "billing" }} />
        <hr />
        <PaymentElement
          options={{
            defaultValues: {
              billingDetails: {
                email: userData?.email,
                name: userData?.name,
              },
            },
          }}
        />
      </div>
      <p className="text-muted mt-4">
        You will be able to review charges on next screen before you are
        charged. We provide 30 day no questions asked refunds.
      </p>
      {coupon?.value ? (
        <span className="text-primary fs-6">
          ({coupon?.value}
          {coupon?.discount_type === "percent" ? "%" : "$"} discount will be
          applied on the total price)
        </span>
      ) : (
        <></>
      )}
    </div>
  );
}

export default function CardModal({
  setSummary,
  setStripe,
  setStripeLoading,
  orgData,
  stripeElementRef,
  confirmedPlan,
  coupon,
}) {
  const { theme, userData } = useContext(DataProvider);
  const [setupPaymentIntentSecret, setSetupPaymentIntentSecret] = useState("");

  useEffect(() => {
    async function getIntentData() {
      try {
        const data = await http().post(
          endpoints.organization.billing.stripe.intent
        );
        setSetupPaymentIntentSecret(data.intent_secret);
      } catch (error) {
        toast.error(error.error.message);
      }
    }
    getIntentData();
  }, []);

  var key =
    orgData && orgData.stripe_account_loc === "sgp"
      ? config.STRIPE_KEY
      : config.STRIPE_KEY_IND;

  const stripePromise = loadStripe(key);

  const options = {
    clientSecret: setupPaymentIntentSecret,
    appearance: stripeCardStyle(theme),
  };

  return (
    <div
      className="row justify-content-center"
      style={{
        width: setSetupPaymentIntentSecret ? "600px" : "100%",
      }}
    >
      <div className={`col-lg-${setSetupPaymentIntentSecret ? "10" : "8"}`}>
        {setupPaymentIntentSecret ? (
          <Elements stripe={stripePromise} options={options}>
            <CardForm
              setSummary={setSummary}
              setStripe={setStripe}
              setStripeLoading={setStripeLoading}
              userData={userData}
              stripeElementRef={stripeElementRef}
              confirmedPlan={confirmedPlan}
              coupon={coupon}
            />
          </Elements>
        ) : (
          <div>
            <p className="card-text placeholder-glow">
              <span className="placeholder col-12"></span>
              <span className="placeholder col-12"></span>
              <span className="placeholder col-4"></span>
              <span className="placeholder col-4"></span>
              <span className="placeholder col-8"></span>
              <br />
              <br />
              <span className="placeholder col-12"></span>
              <span className="placeholder col-12"></span>
              <span className="placeholder col-4"></span>
              <span className="placeholder col-4"></span>
              <span className="placeholder col-8"></span>
              <br />
              <br />
              <span className="placeholder col-12"></span>
              <span className="placeholder col-12"></span>
              <span className="placeholder col-4"></span>
              <span className="placeholder col-4"></span>
              <span className="placeholder col-8"></span>
            </p>
          </div>
        )}
      </div>
    </div>
  );
}
