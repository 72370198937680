export const VIDEOMETRICS = [
  {
    value: "asset_duration",
    label: "Transcoding Minutes",
  },
  {
    value: "bandwidth_consumption",
    label: "Streaming GBs",
  },
  {
    value: "storage_unit",
    label: "Storage Minutes",
  },
];
