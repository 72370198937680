import { routePaths } from "global/routePaths"


export const VIDEO_REPORT_MENU = [
    {
        "name": "Report Details",
        "icon": "bi bi-envelope-check",
        "activeLabel":"Video Collections",
        "id":"report_details",
        "item_route": routePaths.video.reports.editreport
    },
    {
        "name": "Action",
        "icon": "bi bi-trash3",
        "activeLabel":"Collection Delete",
        "id":"delete",
        "item_route":  routePaths.video.reports.deletereport
    },

]
