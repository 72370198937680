export const VIDEO_OUTPUT_FORMATS = [
  {
    value: "abr",
    label: "HLS + DASH",
  },
  {
    value: "mp4",
    label: "MP4",
  },
];
