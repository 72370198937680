export const IMAGEMETRICS = [
  {
    label: "Bandwidth (GBs)",
    value: "bandwidth",
  },
  {
    label: "Total Requests",
    value: "requests",
  },
  {
    label: "2xx Status",
    value: "status_2xx",
  },
  {
    label: "4xx Status",
    value: "status_4xx",
  },
  {
    label: "5xx Status",
    value: "status_5xx",
  },
  {
    label: "Hit Ratio",
    value: "hit_ratio",
  },
  {
    label: "Response Time",
    value: "response_time",
  },
];
