import imageConstants from "./constants/images";
import textConstants from "./constants/text";
import { LEVEL1, LEVEL2 } from "./constants/videoSourceOptions";
import {
  IMAGELEVEL1FORWEB,
  IMAGELEVEL2,
  IMAGELEVEL1,
  CLOUDLEVEL1,
} from "./constants/imageSourceOptions";
import { VIDEOMETRICS } from "./constants/videoMetrics";
import { ALERT_CONDITIONS, ALERT_FREQUENCY } from "./constants/alerts";
import { REPORT_FREQUENCY } from "./constants/reports";
import { VIDEO_MENU } from "./constants/videoMenu";
import { VIDEO_ALERT_MENU } from "./constants/videoAlertMenu";
import { VIDEO_REPORT_MENU } from "./constants/videoReprtMenu";

import { VIDEO_OUTPUT_FORMATS } from "./constants/videoOutputFormats";
import { VIDEO_OUTPUT_RESOLUTIONS } from "./constants/videoResolutions";
import { VIDEO_GENERATE_MP4_VERSION } from "./constants/videoMP4Version";
import { VIDEO_PER_TITLE_ENCODING } from "./constants/videoPerTitleEncoding";
import { VIDEO_CODECS } from "./constants/videoCodecs";
import { EMBED_LOGO_POSITION } from "./constants/embedLogoPosition";
import { COUNTRY_CODES } from "./constants/countryCodes";
import { ENABLE_DRM } from "./constants/videoDRM";

import { IMAGE_MENU } from "./constants/imageMenu";
import { IMAGEMETRICS } from "./constants/imageMetrics";
import { IMAGE_ALERT_MENU } from "./constants/imageAlertMenu";
import { IMAGE_REPORT_MENU } from "./constants/imageReportMenu";
import { VIDEO_PRIVACY } from "./constants/videoSourceOptions";

import {
  HORIZONTAL_ALIGN,
  VERTICAL_ALIGN,
  LANGUAGES,
  PROFILE_MENU,
} from "./constants/profiles";

const constants = {
  image: imageConstants,
  text: textConstants,
  videoPrivacy: VIDEO_PRIVACY,
  videoSources: {
    level1: LEVEL1,
    level2: LEVEL2,
  },
  imageSources: {
    level1: IMAGELEVEL1,
    cloudlevel: CLOUDLEVEL1,
    weblevel: IMAGELEVEL1FORWEB,
    level2: IMAGELEVEL2,
  },
  videoMenu: VIDEO_MENU,
  videoAlertMenu: VIDEO_ALERT_MENU,
  videoReportMenu: VIDEO_REPORT_MENU,
  videoMetrics: VIDEOMETRICS,
  alertConditions: ALERT_CONDITIONS,
  alertFrequency: ALERT_FREQUENCY,
  reportFrequency: REPORT_FREQUENCY,
  videoFormats: VIDEO_OUTPUT_FORMATS,
  videoResolutions: VIDEO_OUTPUT_RESOLUTIONS,
  videoMP4Version: VIDEO_GENERATE_MP4_VERSION,
  videoPTE: VIDEO_PER_TITLE_ENCODING,
  videoCodecs: VIDEO_CODECS,
  embedLogoPosition: EMBED_LOGO_POSITION,
  countryCodes: COUNTRY_CODES,
  enableDrm: ENABLE_DRM,
  profile: {
    horizontal: HORIZONTAL_ALIGN,
    vertical: VERTICAL_ALIGN,
    lang: LANGUAGES,
    profieMenu: PROFILE_MENU,
  },
  imageMenu: IMAGE_MENU,
  imageMetrics: IMAGEMETRICS,
  imageAlertMenu: IMAGE_ALERT_MENU,
  imageReportMenu: IMAGE_REPORT_MENU,
};

export default constants;
