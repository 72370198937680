import axios from "axios";
import { getHeader } from "utils/header";
import { config } from "global/config";
import { emitCustomEvent } from "react-custom-events";
import toast from "react-hot-toast";

// Default API will be your root
const API_ROOT = config.API_URL;
const TIMEOUT = 60000;

const http = ({
  headerType = "json",
  baseURL = API_ROOT,
  timeout = TIMEOUT,
  errorResponse = null,
}) => {
  // errorResponse helps us to override the default behavior of this function in case of errors
  // if errorResponse is passed during the function call that means we want to override error event firing
  // which is handled via the main error listener wrapper function
  const headers = getHeader(headerType);

  const client = axios.create({
    baseURL,
    timeout,
    headers,
  });

  // Intercept response object and handleSuccess and Error Object
  client.interceptors.response.use(handleSuccess, handleError);

  function handleSuccess(response) {
    return response;
  }

  /** Intercept any unauthorized request.
   * status 401 means either accessToken is expired or invalid
   * dispatch logout action accordingly **/
  function handleError(error) {
    if (errorResponse && errorResponse[error.response.status]) {
    } else if (error && !error.response) {
      toast.error(
        (t) => (
          <span>
            <b>
              API is unreachable
              <br />
            </b>{" "}
            Please check your network connectivity
          </span>
        ),
        {
          duration: 6000,
          style: {
            border: "1px solid #feedf1",
            padding: "16px",
            color: "#ed4c78",
            background: "#feedf1",
            textAlign: "start",
          },
        }
      );
    } else if (error && error.response.status === 500) {
      toast("Oops something went wrong, we are looking into it", {
        duration: 6000,
        style: {
          border: "1px solid #713200",
          padding: "16px",
          color: "white",
          background: "red",
          textAlign: "center",
        },
      });
    } else if (error.response.data.error.code === "invalid_api_key") {
      toast.error(error.response.data.error.message);
      window.localStorage.removeItem("property");
      window.localStorage.removeItem("filters");
      window.localStorage.removeItem("page_width");
      window.localStorage.removeItem("last_visited_collection");
      window.localStorage.removeItem("last_visited_folder");
      window.location.href = config.WEBAPP_HOST+"/dashboard/logout/";
    } else {
      emitCustomEvent("apiError", error);
    }
    return Promise.reject(error);
  }

  function get(path) {
    return client.get(path).then((response) => response.data);
  }

  function getResponse(path) {
    return client.get(path).then((response) => response);
  }

  function post(path, payload) {
    return client.post(path, payload).then((response) => response.data);
  }

  function put(path, payload) {
    return client.put(path, payload).then((response) => response.data);
  }

  function patch(path, payload) {
    return client.patch(path, payload).then((response) => response.data);
  }

  function _delete(path, data) {
    if (data) {
      return client
        .delete(path, { data: data })
        .then((response) => response?.data);
    }
    return client.delete(path).then((response) => response.data);
  }

  return {
    get,
    getResponse,
    post,
    put,
    patch,
    delete: _delete,
  };
};

export default http;
