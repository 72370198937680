import React, { useState, useEffect } from "react";
import { useCustomEventListener } from "react-custom-events";
import Page404 from "Pages/Page404/Page404";
import Page401 from "Pages/Page401";
import { useLocation } from "react-router-dom";

export default function ConsoleErrorHandler({ children }) {
  const [error, setError] = useState(null);
  const location = useLocation();

  useEffect(() => {
    // Whenever there is a change in page URL set the error as null
    setError(null);
  }, [location]);

  useCustomEventListener("apiError", (data) => {
    // Whenever the listener receives data i.e. API returns an error set it in the
    // error state
    setError(data);
  });

  if (error && error.response?.status === 404) {
    return <Page404 />;
  }

  if (error && error.response?.status === 401) {
    return <Page401 />;
  }

  // Handle more status checks

  return children;
}
